import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-default.tsx";
import MdxMultiSection from '../../templates/mdx-multi-section.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const NavSecondary = makeShortcode("NavSecondary");
const layoutProps = {
  _frontmatter
};
const MDXLayout = MdxMultiSection;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <a id="collabs"></a>
    <section className="main style1">
      <header className="small">
        <h2 {...{
          "id": "emrg-collaboratories"
        }}>{`EMRG: Collaboratories`}</h2>
        <p>{`Our collaboratories are informal educational exchange programs, supported by the Embodied Media Research Group, that connect UC Irvine students and faculty with peers at other leading institutions. Participants work in collaborative teams, with special coursework and other activities centered on specific topics and themes, exploring ways in which diverse communities can develop and exchange new ideas across disciplinary boundaries.`}</p>
      </header>
      <div className="image filtered" data-position="center">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "2400px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "33.33333333333333%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAIDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAGNBF4gx//EABkQAAEFAAAAAAAAAAAAAAAAAAECAyEjMf/aAAgBAQABBQIOV4Cmf//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AUf/xAAWEQEBAQAAAAAAAAAAAAAAAAAAERL/2gAIAQIBAT8BrT//xAAaEAACAgMAAAAAAAAAAAAAAAAAAREhAjFB/9oACAEBAAY/AnhOyOFOj//EABoQAQACAwEAAAAAAAAAAAAAABEAATFBUfD/2gAIAQEAAT8hNmgMcjzcvbcM/9oADAMBAAIAAwAAABD77//EABYRAQEBAAAAAAAAAAAAAAAAABEQIf/aAAgBAwEBPxBxj//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxDUMv/EABsQAQACAwEBAAAAAAAAAAAAAAERIQAxYVFx/9oACAEBAAE/EIxANNkEX7iycoMO7SFHDR3JSyVnb9z/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "people planting together 3x1",
              "title": "people planting together 3x1",
              "src": "/static/75cc7ea55c470e164d9a271bf280f846/111a0/people-planting-together_3x1.jpg",
              "srcSet": ["/static/75cc7ea55c470e164d9a271bf280f846/3ad8d/people-planting-together_3x1.jpg 600w", "/static/75cc7ea55c470e164d9a271bf280f846/6c738/people-planting-together_3x1.jpg 1200w", "/static/75cc7ea55c470e164d9a271bf280f846/111a0/people-planting-together_3x1.jpg 2400w"],
              "sizes": "(max-width: 2400px) 100vw, 2400px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </div>
      <div className="features">
        <section>
          <h3 {...{
            "id": "tempest"
          }}>{`Tempest`}</h3>
          <p>{`In the `}<strong parentName="p">{`Tempest Collaboratory`}</strong>{` during 2017-18, UCI students from dance, engineering and computer science worked with architecture students from the Rhode Island School of Design (RISD) to design, implement, demonstrate and evaluate a series of Performative Objects. Inspired by natural phenomena and responding to the theme of The Storm, the Performative Objects were artworks and devices intended to be unpredictable, inhabitable and performable.`}</p>
          <ul className="actions special">
  <li><Link to="https://tempest.embodied.net/resources/objects/" className="button small" mdxType="Link">Details</Link></li>
          </ul>
        </section>
        <section>
          <h3 {...{
            "id": "yomo"
          }}>{`YOMO`}</h3>
          <p>{`Performing arts students from UCI and Brown University worked with RISD architecture and industrial design students in the `}<strong parentName="p">{`Your Ocean, My Ocean (YOMO) Collaboratory`}</strong>{` during 2018-19, investigating unintended consequences of technology in ocean environments. Students considered human activities that affect marine ecosystems, devising artistic approaches to make adverse consequences tangible and meaningful. Their work was presented at Brown, RISD and UCI.`}</p>
          <ul className="actions special">
  <li><Link to="https://yomo.embodied.net/" className="button small" mdxType="Link">Details</Link></li>
          </ul>
        </section>
        <section>
          <h3 {...{
            "id": "living-systems"
          }}>{`Living Systems`}</h3>
          <p>{`Co-produced by EMRG and the Buckminster Fuller Institute during 2020-21, the `}<strong parentName="p">{`Living Systems Art + Design Collaboratory`}</strong>{` sponsored UCI student artists to create projects responding to the "living systems" concept. Participants received financial awards and other support to implement projects focused on helping people perceive biological, social, ecological, philosophical, spiritual, mathematical, political and technological dimensions of life as a unified whole.`}</p>
          <ul className="actions special">
  <li><Link to="/collab/living-systems/" className="button small" mdxType="Link">Details</Link></li>
          </ul>
        </section>
      </div>
    </section>
    <section className="main style2">
      <footer>
  <div className="row aln-center gtr-50 gtr-uniform">
    <NavSecondary mdxType="NavSecondary" />
  </div>
  <div className="row">
    <div className="col-12 footnote">
      Image credit: @lovelyday12
    </div>
  </div>
      </footer>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      